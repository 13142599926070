$border: solid 1px rgba(0,0,0,0.12);

// Materialize variables
$roboto-font-path: '../fonts/';
$primary-color: #5E2750;
$secondary-color: #C64012;
$link-color: $secondary-color;

@import '../bower_components/materialize/sass/materialize';
@import '../bower_components/nprogress/nprogress';
@import 'material-icons.scss';
@import 'colors.scss';
@import 'tables.scss';
@import 'collapsible.sass';
@import 'app.scss';
@import 'user-form.scss';
@import 'login';
@import 'forms';
@import 'input-attachments';
@import 'input-collection';
@import 'input-options';
@import 'input-chips';
@import 'collection';
@import 'input-select';
@import 'branch-products';
@import 'modal';
@import '../bower_components/magnific-popup/dist/magnific-popup';
@import 'magnific-popup';
@import 'editable-cell';
@import 'select-cell';
