.input-chips {
  label {
    font-size: 0.8rem;
    transform: translateY(-140%); }

  .items {
    margin-bottom: 20px;
    border-bottom: solid 1px rgb(158, 158, 158);
    &.invalid {
      border-bottom: solid 2px rgb(244, 67, 54); } }

  .chip {
    margin: 5px 5px 5px 0;
    outline: none;
    cursor: pointer;
    &.invalid:not(:focus) {
      span {
        color: rgba(243, 45, 34, 0.9); } }

    .material-icons {
      cursor: pointer;
      float: right;
      font-size: 16px;
      line-height: 32px;
      padding-left: 8px; } } }
