table {
  background: #FFF;

  border-radius: 0 0 3px 3px;
  max-width: 1280px;
  margin: 0 auto;
  td, th {
    padding: 10px;
    &:first-child { padding-left: 20px; }
  }
  caption {
    font-size: large;
    background: #FFF;
    text-align: left;
    padding: 5px 10px 5px 20px;
    line-height: 36px;
    .breadcrumb-nav {
      display: inline-block;
      padding-left: 10px;
      margin: 0;
      line-height: normal;
      a {
        padding: 0;
        cursor: pointer;
        text-decoration: none;
        font-size: small !important;
        &:last-child { cursor: initial; }
        &:hover { vertical-align: middle; }
        &::before { margin: 0; }
      }
    }
    .breadcrumb-undiscountable-icon, .breadcrumb-ecommerce-disabled-icon {
      display: inline-block;
      padding: 0 8px;
      transform: translateY(5px);
    }
    // dentro de este ul se pone el botón de filtro
    ul.right {
      margin: 0;

      & > li {
        padding-left: 10px;
        display: inline-block;
        position: relative;

        & > a {
          cursor: pointer;
        }
      }
    }
  }

  thead {
    border-bottom: $border;
    font-size: small;
    th { font-weight: 500; }
  }
  tfoot {
    border-top: $border;
    .load-more-link {
      cursor: pointer;
      float: left;
    }
    .load-more-link:not(.hide) ~ .collection-info {
      margin-right: 168px;
    }
    .collection-info {
      font-weight: bold;
      font-size: small;
      font-style: italic;
    }
  }
  tbody {
    tr {
      span {
        .btn-flat {
          position: absolute;
          transform: translate(0, -20%);
          color: $disabled-text-color;
          cursor: default;
           &:hover {
            background-color: transparent;
          }
        }
        &.row-icon {
          .btn-flat {
            display: inline-block;
            max-height: 20px;
            transform: translate(0, -50%);
            padding: 0 6px;
            position: relative;
          }
        }
      }
      .record-actions {
        position: relative;
        width: 0;
        white-space: nowrap;
        div {
          visibility: hidden;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
          text-align: right;
          a {
            padding-right: 15px !important;
            display: inline;
            min-width: 36px;
          }
        }
        .btn-flat {
          padding: 0 10px;
          text-align: center;
          padding: 0;
          width: 36px;
          border-radius: 20px;
        }
      }
    }
    tr:hover .record-actions div { visibility: visible; }
  }
}

table.bordered {
  border: $border;
  border-radius: 2px;
  border-top: none;
  thead {
    border-top: $border;
  }
  caption {
    border: $border;
    border-bottom: none;
    & ~ thead {
      border-top: none;
    }
  }
}

td.invalid {
  background-color: #F0C2C2;
  & > span {
    color: $input-error-color;
  }
}

td.disabled {
  span {
    color: $secondary-text-color;
  }
}

td.col-amount, td.col-perc {
  text-align: right
}
