.input-attachments {
  margin-top: 3px;

  >label {
    font-size: 0.9rem; }

  >div {
    margin: 0 0 15px;
    padding: 5px 5px 0 5px;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    border-bottom: 1px solid #9e9e9e;
    background: rgba(0, 0, 0, .05);
    position: relative;

    input {
      display: none; }

    ul {
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        position: relative;
        overflow: hidden;
        background-color: #fff;

        .actions {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 10;
          display: inline-flex;
          background-color: rgba(255,255,255,0.3);
          border-radius: 2px;
          visibility: hidden;

          a {
            flex: 1 1 auto;
            padding: 0;
            vertical-align: top;
            height: 24px;
            text-align: center;
            color: rgba(0, 0, 0, 0.38); }

          a:hover {
            color: rgba(0, 0, 0, 0.87);
            background-color: rgba(255,255,255,0.5); }

          i,
          i:before {
            height: 24px;
            line-height: 24px;
            font-size: 24px; } } }

      li:hover .actions {
        visibility: visible; }

      li .spinner-overlay {
        position: absolute;
        text-align: center;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.7); } }

    .btn-add-picture,
    .btn-add-spec {}
    .btn-add-letter {
      position: absolute;
      right: 3px;
      bottom: 3px; } }

  >div.pictures {
    ul {
      min-height: 106px; }
    li {
      width: 100px;
      height: 100px;
      margin-right: 5px;
      img {
        height: 100%;
        width: 100px;
        object-fit: cover;
        border-radius: 2px; }
      .spinner-overlay {
        padding-top: 25px; } } }

  >div.specs,
  >div.letters {
    ul {
      padding-bottom: 38px; }
    li {
      width: 100%;
      height: 36px;
      .link {
        line-height: 36px;
        margin: 0 10px; } } }

  >div.picture {
    ul {
      height: 106px;
      text-align: center;
      width: 100%; }

    li {
      margin-right: 5px;
      max-height: 100px;
      max-width: 90%;

      img {
        border-radius: 2px;
        max-height: 100px;
        max-width: 100%;
        object-fit: scale-down; }

      .spinner-overlay {
        padding-top: 25px; } } } }
