.input-collection {
  margin-top: 3px;

  >label {
    font-size: 0.9rem; }

  >ul {
    margin: 0 0 5px;

    //collection of single inputs
    >li>.input-field {
      margin-top: 0;
      input {
        margin-bottom: 10px;
        height: 2.5rem; } }
    >li>.input-field:after {
      top: 37px; } } }
