.select-cell {
  .input-field {
    padding: 0;
    margin: 0;
    input {
      margin: 0;
      border-bottom: none;
      height: 1rem;
      &:focus {
        box-shadow: none; } }
    .caret {
      color: $secondary-text-color;
      font-size: 9px; }
    .select-wrapper {
      .dropdown-content {
        transform: translate(-16px, -18px);
        li:first-child {
          display: none; }
        span {
          font-size: 14px; } } } } }
