.outer-view {
  background: #E6E6E6;
  flex: 1;
  height: 100%;

  .valign {
    width: 100%; } }

.form-login {
  max-width: 400px;
  margin: 0 auto;

  .login-title {
    margin-top: 0;
    border-radius: 2px 2px 0 0;
    padding: 15px 5px;
    text-align: center;
    color: #fff;
    background: $primary-color;

    .logo {
      margin: 0;
      padding: 0;
      display: inline-block;
      * {
        float: left;
        font-size: 2.8rem;
        line-height: 50px; } }
    .welcome-text {
      display: inline-block;
      width: 100%; } }

  #login-form-bottom {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px; }

  #login-links {
    display: flex;
    flex-direction: column;
    justify-content: center; }

  .forgot-pass {
    &:hover {
      text-decoration: underline; } }

  .login-container > div {
    width: 100%; } }
