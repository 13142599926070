.collection {
  margin: 0;
  overflow: initial;
  .collection-header {
    font-size: large;

    ul.right > li {
      padding-left: 10px;
      display: inline-block;

      & > a {
        cursor: pointer; } } } }

.index-collection .collection-item {
  position: relative;
  transition: background-color .25s ease;

  &:hover {
    background-color: #f2f2f2;
    .row-operations {
      visibility: visible; } }

  .row-operations {
    visibility: hidden;

    & > a {
      cursor: pointer;
      transition: none;
      color: rgba(0,0,0,.38);
      margin: 0 0 0 0.5rem;
      padding: 0 0.75rem;
      &:hover {
        color: rgba(0,0,0,.87); }
      i {
        line-height: unset;
        height: unset; } } }

  .secondary-content {
    @extend .row-operations;
    padding: 0;
    margin-right: 10px;
    position: absolute;
    float: right;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    text-align: right;

    & > a.active {
      color: $secondary-color;
      &:hover {
        color: lighten($secondary-color,10%); }
      i {
        font-weight: 600; } } }

  .info {
    color: rgba(0, 0, 0, 0.71);
    font-size: 13px;
    margin: 0;
    line-height: 1.3rem; }

  &.products-index__item {
    padding-right: 50px;
    padding-left: 115px;
    min-height: 83px;

    img {
      position: absolute;
      left: 7.5px;
      top: 7.5px;
      height: 75px;
      width: 100px;
      border-radius: 2px;
      object-fit: cover;
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.11), 0 1px 2px rgba(0, 0, 0, 0.22); }

    .content {
      .category {
        font-size: 10px;
        line-height: 14px;
        display: block; }

      .description {
        font-size: 16px; } } }

  &.reports-index__item {
    .format-btn {
      text-transform: uppercase; } }

  &.notification {
    padding: 12px 50px 12px 60px;
    min-height: 44px;
    >i {
      position: absolute;
      left: 15px;
      top: 7px; }
    span {
      vertical-align: middle; }
    >.message {
      margin: 0;
      word-wrap: break-word;
      white-space: normal; }
    >.time-ago {
      padding-right: 10px;
      color: $secondary-text-color; } }



  &.dropdown-notification {
    width: 500px;
    padding-right: 50px;
    padding-left: 72px;
    $icon-size: 3rem;
    >i {
      line-height: $icon-size;
      font-size: $icon-size;
      height: $icon-size;
      width: $icon-size;
      position: absolute;
      left: 15px; }
    >p {
      margin: 0;
      word-wrap: break-word;
      white-space: normal; }
    >small.time-ago {
      position: absolute;
      bottom: 2px;
      right: 10px; }
    .secondary-content {
      top: 30%; } } }

.index-collection .collection-footer {
  min-height: initial;
  text-align: center;
  line-height: 1.5;
  font-size: 14px;
  padding: 10px;
  color: rgba(0,0,0,.54);
  background: #FFF;
  a, span {
    padding: 0;
    font-weight: 700;
    font-size: small;
    font-style: italic; }
  a {
    position: absolute;
    color: $secondary-color;
    left: 10px;
    cursor: pointer; }
  a:hover {
      background: transparent; } }

.dropdown-content {
  .collection-item.notification {}

  .collection-footer {
    padding: 0;
    a {
      position: static;
      padding: 10px 0; } } }

