.input-select {
  .dropdown-content {
    display: block;
    opacity: 1;
    margin: 0 10.5px;
    top: 44px;
    left: 0;
    right: 0; } }

// Estilo para el selector múltiple
.select-wrapper {
  .dropdown-content {
    max-height: 300px; } }
