.input-options {
  margin-top: 1rem;
  position: relative;

  >label {
    position: absolute;
    top: 0.8rem;
    font-size: 0.8rem;
    transform: translateY(-140%); }

  >ul {
    margin: 0 0 15px;
    padding: 0;
    border-bottom: 1px solid #9e9e9e;
    min-height: 3rem;
    >li {
      line-height: 3rem; } } }
