.branch-products {
  .table-wrapper {
    .blank-model {
      .btn-remove, .btn-copy {
        display: none; } }
    .btn-paste {
      display: none; }
    &.can-paste .btn-paste {
      display: inline-block; }
    .has-errors {
      display: none;
      i {
        color: $input-error-color; } }

    tbody.invalid {
      .has-errors {
        display: inline-block;
        visibility: visible; } } }

  input:disabled {
    color: $secondary-text-color; }
  .table-wrapper {
    position: relative;
    max-width: 100%;
    padding-top: 77px;
    border: solid 1px rgba(160, 160, 160, 0.3);

    .headers {
      position: absolute;
      top: 0;
      width: 100%;
      overflow: hidden;
      th {
        padding-top: 0; } }
    .data {
      min-height: 150px;
      max-height: 50vh;
      overflow: auto;
      .row-data {
        height: 32px;
        border: none;
        border-bottom: solid 1px rgba(160, 160, 160, 0.2); }
      td {
        padding-top: 5px;
        padding-bottom: 5px; } }
    tr.column-guide {
      td {
        padding-top: 0;
        padding-bottom: 0;
        margin: 0; } }

    table {
      width: 0;
      margin-left: 0;
      table-layout: fixed;
      tbody {
        &:hover {
          background-color: #f2f2f2;
          .secondary-content a {
            visibility: visible; } } }
      th {
        color: $secondary-text-color;
        font-size: 12px; }
      .col-branch {
        padding-bottom: 0;
        overflow: visible;
        white-space: nowrap;
        span {
          color: $secondary-color-dark;
          font-size: 12px;
          line-height: 12px; }
        .secondary-content {
          display: inline-block;
          float: none;
          line-height: unset;
          position: absolute;
          top: 50%;
          transform: translate(0, -35%);
          visibility: hidden;
          margin-left: 1rem;
          a {
            height: unset;
            line-height: unset;
            color: rgba(0,0,0,.38);
            padding: 0 0.5rem;
            &:hover {
              color: rgba(0,0,0,.87); }
            i {
              line-height: unset;
              height: unset; } } }

        .confirm-remove {
          max-width: 280px; } }

      .col-currency {
        width: 70px; }
      .col-amount {
        width: 130px; }
      .col-last-stock-layer-cost, .col-last-supplier-invoice-cost {
        cursor: pointer;
        span {
          display: inline; } }
      .col-scale {
        width: 250px; }
      .col-perc {
        width: 100px; }
      .col-right-spacer {
        width: 500px;
        border-bottom: none; }
      .col-separator {
        border-left: solid 1px rgba(160, 160, 160, 0.2); } } }

  .editable-cell {
      .input-field {
        margin-top: -12px; } }

  .checkbox-cell {
    text-align: center;
    label {
      height: 20px;
      padding-left: 20px;
      vertical-align: text-bottom; } } }
