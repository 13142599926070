$mfp-image-padding-top: 40px;
$mfp-image-padding-bottom: 40px;
$mfp-image-background-color: #fff;

img {
  &.mfp-img {
    background-color: $mfp-image-background-color;
    margin: $mfp-image-padding-top auto $mfp-image-padding-bottom;
    padding: 0; } }

button {
  &.mfp-close,
  &.mfp-arrow {
    &:focus {
      background-color: transparent; } } }
