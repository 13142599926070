.modal form {
  .modal-title {
    margin-bottom: 30px;
    padding: 18px 20px 0;
    font-weight: 400;
    font-size: 24px;
    margin: 0; } }

form.card .card-title {
  padding: 18px 20px 0;
  font-weight: 400;
  border-radius: 2px 2px 0 0; }

form.card .card-action,
form .modal-footer {
  padding: 8px !important; }

form .form-section-header {
  display: block;
  min-height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 1rem;
  border: none;
  color: rgba(0, 0, 0, .54);

  i {
    width: 2rem;
    font-size: 1.6rem;
    line-height: 2.5rem;
    display: block;
    float: left;
    text-align: center;
    margin-right: 0.5rem; } }

.modal .card {
  margin: 0 !important; }

.modal form .modal-content {
  padding: 20px; }

.form-array {
  & > .collapsible {
    margin-top: 0; } }
