.editable-cell {
  position: relative;
  cursor: pointer;

  .placeholder {
    color: rgba(0, 0, 0, .28); }

  .input-field {
    position: absolute;
    top: 0;
    left: 0;
    background: #FFF;
    z-index: 1000;
    width: 100%;
    margin-top: 0;

    input {
      height: 34px;
      padding-top: 10px; } } }

.editable-cell.rigth-align {
  .input-field input {
    text-align: right; } }
