.collapsible {
  &.permissions, &.credit-limits {
    border: none;
    box-shadow: none;
    & > li {
      border-bottom: 1px solid $disabled-text-color;
      .collapsible-header {
        border: none;
        i {
          margin: 0 5px 0 -5px;
          &:focus {
            color: $secondary-color;
            outline: none; } }
        &.active {}
        color: $secondary-text-color; }
      .collapsible-body {
        border-bottom: none; } } } }
