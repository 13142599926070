$primary-text-color: rgba(0, 0, 0, .87);
$secondary-text-color: rgba(0, 0, 0, .54);
$disabled-text-color: rgba(0, 0, 0, .38);

$secondary-color-light: lighten($secondary-color, 8%);
$secondary-color-dark: darken($secondary-color, 15%);

$background: #F5F5F5;
$background-lighten: #FAFAFA;
$background-darken: #E0E0E0;

body {
  color: $primary-text-color;
  background: $background;
}

.input-field .prefix {
  color: $disabled-text-color;
}

.dropdown-content li > a,
.dropdown-content li > span {
  color: $secondary-text-color;
}

.side-nav * {
  color: $secondary-text-color;
}

.btn-flat:focus {
  background-color: transparent;
}

.card .card-action {
  button,
  a:not(.btn):not(.btn-large):not(.btn-floating) {
    color: $secondary-color;
    &:hover {
      color: $secondary-color-light;
    }
  }
}

#erpro-welcome i {
  color: $disabled-text-color;
}

.home-title {
  color: $primary-text-color;
}

.home-slogan {
  color: $primary-text-color;
}

[type="checkbox"] + label {
  color: $secondary-text-color;
}

table caption ul.right a,
.collection-header ul.right a {
  color: $secondary-text-color;
  &:hover {
    color: $primary-text-color;
  }
}

table {
  .breadcrumb-undiscountable-icon, .breadcrumb-ecommerce-disabled-icon {
    i {
      color: $secondary-color;
    }
  }
  .breadcrumb-nav {
    a {
      color: $secondary-color-dark;
      &:hover {
        color: $secondary-color;
      }
    }
    .breadcrumb {
      &:last-child {
        color: $secondary-color;
      }
      &::before {
        color: $secondary-color-dark;
      }
    }
  }
  thead th {
    color: $disabled-text-color;
  }
  tfoot {
    color: $secondary-text-color;
  }
  tr .record-actions {
    a:hover {
      color: $primary-text-color;
    }
    .btn-flat {
      color: $disabled-text-color;
    }
  }
}

#nprogress {
  .bar {
    background: $secondary-color;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
  }
  .peg {
    box-shadow: 0 0 10px $secondary-color-light, 0 0 5px $secondary-color-light;
  }
}

.addresses {
  border:none;
  box-shadow: none;
  .entry {
    border-bottom: 1px solid $disabled-text-color;
    .collapsible-header{
      border: none;
      i {
        margin: 0 5px 0 -5px;
      }:focus {
        color: $secondary-color;
        outline: none;
      }
    }.active {
      color: $secondary-text-color;
    }
    .collapsible-body {
      border-bottom: none;
    }
  }:last-child .collapsible-header {
      color: $secondary-text-color;
  }
}

#filter-btn.filtered {
  color: rgba(33, 150, 243, 1)
}

.record-actions .btn-flat:hover,
.secondary-content .btn-flat:hover,
.collapsible-header .btn-flat:hover,
.entries .btn-flat:hover,
.entry .btn-flat:hover {
  background-color: transparent;
}

.chips .chip:focus {
  background-color: $secondary-color;
  color: #FFF;
}
