a {
  outline: none;
}

@media (min-width:993px) {
  .side-nav.sidebar-show {
    transform: none;
  }

  .sidebar-show ~ header #topbar {
    margin-left: 258px;
  }

  .sidebar-show ~ #content,
  .sidebar-show ~ footer {
    margin-left: 240px;
  }

  .sidebar-show ~ header #sidebar-show {
    display: none;
  }

  .sidebar-show {
    left: 0;
  }
}

@media (max-width:992px) {
  .sidebar-float {
    left: 240px;
  }

  .navbar-fixed {
    z-index: 997 !important;
  }
}

@media (max-width: 600px) {
  .footer-copyright {
    text-align: center;
  }

  #content {
    padding: 0;
  }
}

@media (min-width: 601px) {
  #content {
    padding: 20px;
  }
}

.side-nav.sidebar-show {
  width: 240px;
}

#wrapper {
  height: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

header i.left {
  margin-right: 0;
}

.notif-counter {
  display: inline-block;
  background: #FFF none repeat scroll 0% 0%;
  border-radius: 20px;
  height: 15px;
  min-width: 15px;
  line-height: 15px;
  font-weight: bold;
  padding: 0px 5px;
}

.collapsible-header.active i.arrow-down {
  display: none;
}

.collapsible-header:not(.active) i.arrow-down {
  display: block;
}

.collapsible-header.active i.arrow-up {
  display: block;
}

.collapsible-header:not(.active) i.arrow-up {
  display: none;
}

#sidebar > ul {
  margin: 0;
}

#sidebar > ul > li {
  a {
    padding-left: 15px;
  }
}

#sidebar li,
#sidebar a,
#sidebar i {
  padding-right: 0 !important;
  margin: 0 !important;
}

#sidebar a {
  color: rgba(0, 0, 0, 0.68);
  &.active-menu-item {
    color: $primary-color;
  }
}

#sidebar .collapsible-body a {
  margin-left: 0 !important;
  padding-left: 50px;
}

#sidebar li:not(#sidebar-logo) a,
#sidebar li:not(#sidebar-logo) i {
  line-height: 45px !important;
  height: 45px !important;
}

#sidebar li:not(#sidebar-logo) i {
  width: 2rem;
  font-size: 1.6rem;
  float: left;
  text-align: center;
}

#sidebar a:hover {
  background-color: inherit;
}

#sidebar-logo {
  border-bottom: 1px solid rgba(0, 0, 0, .13);
  position: relative;
}

#sidebar-logo:hover {
  background-color: inherit;
}

#sidebar-logo {
  height: 63px;
  padding: 0 40px 0 20px;
  .sidebar-close {
    height: 63px;
    line-height: 63px;
  }
}

#sidebar-logo .logo,
#sidebar-logo .logo i {
  float: left;
  font-weight: normal;
  color: rgba(0,0,0,0.68);
  height: 63px;
  line-height: 63px;
  font-size: 36px;
}

#sidebar-logo .sidebar-close {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 20px;
  width: 40px;
  text-align: center;
}

#sidebar-logo .sidebar-close:hover {
  background-color: #DDD;
}

#content {
  flex: 1 0 auto;
  overflow-x: auto;
  overflow-y: hidden;
}

#sidebar-show {
  padding: 0 18px;
}

#sidebar-show,
.sidebar-close {
  cursor: pointer;
}

#app-title {
  font-size: 18px;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background-color: rgba(0, 0, 0, .5);
  display: none;
}

#dropdown-notif .collection-item > * {
  padding: 0;
}

.fixed-action-btn {
  bottom: 48px;
}

.page-footer {
  padding-top: 0 !important;
  height: auto !important;
}

.footer-copyright {
  padding: 0 !important;
  height: 30px !important;
  line-height: 30px !important;
}

.active-menu-item {
  font-weight: bold;
}

.btn-flat {
  padding: 0 1rem;
}

#change-password-modal {
  max-width: 400px;
}

.disabled-record-row td, td.disabled {
  color: rgba(0, 0, 0, 0.37);
  text-decoration: line-through;
}

.nowrap-text {
  max-width: 100%;
  overflow: hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
}

footer.page-footer {
  margin-top: 0;
}

#location-tabs {
  max-width:1280px;
  margin: 0px auto;
  overflow: hidden;
  background-color: transparent;
}

.switch {
  height: 24px;
}

.entry {
  position: relative;
}

.collapsible-header .btn-remove-entry {
  opacity: .38;
  display: none;
  right: -13px;
  top: 1px;
  padding: 0;
  margin-right: -20px;
  position: initial;
}

.collapsible-body.row {
  margin: 0;
}

.email-input {
  box-sizing: padding-box !important;
  margin-bottom: 10px !important;
  height: 38px !important;
  line-height: 38px !important;
}

.entry:hover:not(:last-of-type) .btn-remove-entry,
.entry:not(:last-of-type) input:focus + .btn-remove-entry {
  display: inline-block;
}

.entry:last-of-type .btn-remove-entry {
  display: none;
}

.btn-remove-entry:hover {
  opacity: 1;
}

#erpro-welcome {
  text-align: center;

  i {
    font-size: 20rem;
  }

  .home-title {
    font-size: 4.2rem;
    margin: 8px;
  }

  .home-slogan {
    font-size: 2.2rem;
    margin: 8px;
  }
}

.autocomplete-results {
  margin-left: -0.75rem;
  padding: 0 0.75rem;
  width: 100%;
  position: absolute;
  top: 45px;
  z-index: 1000000;
}

.autocomplete-results ul {
  position: static;
  display: block;
  opacity: 1;
  overflow-y: auto;
}

.autocomplete-field:before {
  color: rgba(0, 0, 0, .27);
  position: absolute;
  top: 0px;
  height: 3rem;
  line-height: 43.5px;
  content: attr(data-hint);
  user-select: none;
}

.autocomplete-field input {
  cursor: pointer;
}

.toast {
  display: block;
}

.toast ul.browser-default {
  list-style-position: initial;
  list-style-type: disc;
  padding-left: 18px;
}

.input-field:after {
  content: attr(data-error);
  color: #F44336;
  opacity: 1;
  display: block;
  position: absolute;
  top: 45px;
  font-size: 0.8rem;
}

/* FIXME:
  Estas clases son para poder obrener los iconos que no incorpora
  materializecss en su fuente de iconos
*/

.mdi-navigation-subdirectory-arrow-right:before {
  font-family: 'Material Icons';
  content: '\E5DA'
}

.mdi-action-zoom-in:before {
  font-family: 'Material Icons';
  content: '\E8FF'
}

.mdi-navigation-close:before {
  font-family: 'Material Icons';
  content: '\E5CD'
}


.input-field .prefix {
  line-height: 3rem;
}

.upper {
  text-transform: uppercase;
}

nav ul a.dropdown-button {
  display: inline-block;
}

#barcode-quantity-table {
  thead {
    border-bottom: 0;
  }

  th {
    font-weight: normal;

    &:first-child {
      padding-left: 10px;
    }
  }

  .input-field {
    margin-top: 0;
  }

  td {
    padding: 0 10px;
  }

  .barcode-value {
    width: auto;
  }

  .barcode-quantity {
    width: 130px;
  }

  .barcode-operations {
    /* Esto es para prevenir que se muevan un poco los campos cuando
     * pasamos el mouse por encima (y hacemos que la "x" aparazca). 18px es
     * el ancho del character; 1rem es un padding que agrega
     * .btn-remove-entry; 20px es el padding del <td> x2. */
    width: calc(18px + 1rem + 20px);
    button {
      position: static;
    }
  }
}

.collapsible-header {
  background-color: transparent;
}
