.modal {
  overflow-y: visible;

  .modal-footer {
    border-top: 1px solid rgba(160, 160, 160, .2);
    padding: 10px;

    .btn-flat {
      color: $secondary-color;
      margin: 0;
      margin-left: 15px; } }

  &.modal-lg {
    width: 70%; }

  &.modal-overflow {
    overflow-y: auto; } }

.modal--spinner {
  max-width: 400px;

  .modal-content {
    min-height: 90px;
    padding-left: 80px;
    position: relative;

    .spinner {
      left: 25px;
      position: absolute;
      top: 25px; }

    .message {
      color: rgba(0, 0, 0, .57); } } }
